import React from 'react';
import Head from '../components/head'
import Layout from '../components/layout'
import {Link} from "gatsby"
import errorStyles from "../styles/error.module.css"

export default function ErrorPage(){
    return <div>
        <Layout>
        <Head title="404 Page Not Found"/>
            <p className={errorStyles.error}>404</p> 
            <p className={errorStyles.one}>Oops, page not found...</p>
            <Link to="/" className={errorStyles.second}>Go back to home</Link>
        </Layout>
        </div>
}